<template>
  <d-container fluid>
    <pageTitle title="Leverancier" :back="true"/>
    <d-row>
      <vCard :col="8"
             header="Leverancier adressen"
             :actions="actions">
        <editAddress :address-updated="editForm"
                     :address-id="$route.params.address_id"
                     :disabled="isFormReadOnly"/>
      </vCard>
      <vCard :col="4"
             header="Leverancier contact persoon">
      </vCard>
    </d-row>
  </d-container>
</template>


<script>

export default {
  name: 'supplierAddress',
  components: {
    editAddress: () => import('@/components/forms/supplierAddress/editSupplierAddress')
  },
  data() {
    return {
      actions: [],
      actionOptions: {
        0: [
          {
            icon: '<i class="material-icons">edit</i>',
            fn: () => {
              this.editForm()
            }
          }
        ],
        1: [
          {
            icon: '<i class="material-icons">clear</i>',
            fn: () => {
              this.editForm()
            }
          }
        ]
      },
      isFormReadOnly: true
    }
  },
  created() {
    this.actions = this.actionOptions[0]
  },
  methods: {
    test(tst) {
    },
    editForm() {
      this.isFormReadOnly = !this.isFormReadOnly
      this.actions = this.actionOptions[(this.isFormReadOnly ? 0 : 1)]
    }
  }
}
</script>

<style>

</style>